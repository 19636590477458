import { json, type LoaderFunction } from '@remix-run/node'
import { useLoaderData } from '@remix-run/react'
import styled, { css } from 'styled-components'

import Hero from './__components/Hero'
import TheLemonWay from './__components/TheLemonWay'
import ZeroCost from './__components/ZeroCost'

import builderImages from '~/constants/builderImages'
import { getFaqQuestionsByPathname } from '~/constants/faqQuestions'
import paths from '~/constants/paths'
import Faq from '~/sections/Faq'
import Media from '~/sections/Media'
import cacheUtils from '~/utils/cache.utils.server'

const Container = styled.div(() => css``)

export const links = () => [
  {
    rel: 'preload',
    href: builderImages.hero_home,
    as: 'image',
    type: 'image/jpg',
  },
  {
    rel: 'preload',
    href: builderImages.hero_home_mobile,
    as: 'image',
    type: 'image/jpg',
  },
]

export const loader: LoaderFunction = async ({ request }) => {
  const url = new URL(request.url)

  const ctaButtonLink = new URL(paths.ACQUISITION_FLOW_HOME, url.origin)
  ctaButtonLink.searchParams.set('utm_source', 'marketing_midia_on')
  ctaButtonLink.searchParams.set('utm_medium', 'site')
  ctaButtonLink.searchParams.set('utm_campaign', 'home_organic')

  const body = {
    questions: getFaqQuestionsByPathname(url.pathname),
    ctaButtonLink: ctaButtonLink.toString(),
  }

  const cacheValidation = cacheUtils.validateEtag(request, JSON.stringify(body))
  if (cacheValidation.cacheHit) return cacheValidation.response

  return json(body, {
    headers: cacheUtils.createCacheHeaders({
      etag: cacheValidation.etag,
      browserStrategy: 'FOR_ONE_MINUTE',
      isPrivate: true,
    }),
  })
}

export default function Index() {
  const { ctaButtonLink } = useLoaderData<typeof loader>()

  return (
    <Container>
      <Hero ctaButtonLink={ctaButtonLink} />
      <TheLemonWay id="energia-do-jeito-lemon" />
      <ZeroCost />
      <Media />
      <Faq />
    </Container>
  )
}
